
import { Component, Prop, Vue } from "vue-property-decorator";
import { IOwnerLead } from "@/models/OwnerLeads";
import OwnerLeadCard from "@/components/OwnerLeadCard.vue";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyAlertMessage } from "housfy-ui-lib";

@Component({
  name: "OwnerLeads",
  components: {
    OwnerLeadCard,
    HousfyLink,
    HousfySvg,
    HousfyAlertMessage,
  },
})
export default class OwnerLeads extends Vue {
  @Prop({ type: Array, default: () => [] })
  leads!: IOwnerLead[];
  @Prop({ type: Boolean, default: false })
  showAlert!: boolean;
  @Prop({ type: String, default: "" })
  alertMessage!: string;
  @Prop({ type: String, default: "" })
  status!: string;
}
