var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.containerComponent,{tag:"component",staticClass:"min-w-0",attrs:{"to":_vm.propertyLink,"tracking":'link_owner-' + _vm.lead.statusCategory + '-leads_' + _vm.lead.propertyUuid,"data-qa":_vm.dataQa}},[_c('HousfyPropertyCard',{attrs:{"location":_vm.locationName,"address":_vm.lead.fullAddress,"floor-door":_vm.floorDoor,"photo":_vm.lead.mainPhoto,"clickable":_vm.clickable},scopedSlots:_vm._u([(_vm.statusData)?{key:"status",fn:function(){return [_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row items-center"},[_c('p',{staticClass:"text-size3 text-black"},[_vm._v(" "+_vm._s(_vm.statusData ? _vm.$t(_vm.statusData.title) : "")+" ")]),(_vm.statusData && _vm.statusData.alertIcon)?_c('HousfySvg',{staticClass:"ml-4",attrs:{"svg":"icons/alert","width":"16","height":"16"}}):_vm._e()],1),(_vm.statusSubtitle)?_c('p',{staticClass:"text-size2 text-gray40",domProps:{"innerHTML":_vm._s(_vm.statusSubtitle)}}):_vm._e()]),(_vm.statusData && _vm.statusData.badgeText)?_c('div',{staticClass:"rounded-lg px-8 flex items-center justify-center badge",class:{
          'text-white font-bold rent':
            _vm.lead.statusCategory === _vm.statusCategory.PUBLISHED_AD,
          'text-white font-bold rented':
            _vm.lead.statusCategory === _vm.statusCategory.RENTED_NO_MANAGEMENT,
          'text-gray70 cancelled':
            _vm.lead.statusCategory === _vm.statusCategory.FINALIZED ||
            _vm.lead.statusCategory === _vm.statusCategory.LOST,
          'text-white font-bold bg-green60 pr-8 pl-5':
            _vm.lead.statusCategory === _vm.statusCategory.RENTED_IN_MANAGEMENT,
        }},[(_vm.statusData.badgeIcon)?_c('HousfySvg',{class:_vm.isRentedInManagement ? 'mr-5' : 'mr-4',attrs:{"svg":_vm.statusData.badgeIcon,"width":_vm.isRentedInManagement ? '16' : '12',"height":_vm.isRentedInManagement ? '16' : '12'}}):_vm._e(),_c('span',{staticClass:"badge-text text-size2"},[_vm._v(" "+_vm._s(_vm.statusData ? _vm.$tc(_vm.statusData.badgeText) : ""))])],1):(_vm.lead.stepsCompleted && _vm.lead.totalSteps)?_c('div',{staticClass:"w-40 progress-circle"},[_c('HousfyProgressCircle',{attrs:{"percentage":(_vm.lead.stepsCompleted / _vm.lead.totalSteps) * 100,"stroke-color":"#11AEFA","stroke-width":10}})],1):_vm._e()]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }