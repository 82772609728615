export const OWNER_LEAD_STATUS = {
  PendingAd: {
    title: "common.pendingAd",
    subtitle: "common.stepsCompleted",
    needsStepInfo: true,
    alertIcon: true,
  },
  PublishedAd: {
    title: "common.publishedAd",
    subtitle: "common.xTimeAgo",
    badgeIcon: "icons/home-basic",
    badgeText: "common.forRent",
  },
  ReservationSignedPendingRentalLeasingSignature: {
    title: "common.inProcessOfRent",
    subtitle: "common.stepsCompleted",
    needsStepInfo: true,
    alertIcon: true,
  },
  RentedNoManagement: {
    title: "common.processCompleted",
    subtitle: "common.xTimeAgo",
    needsDateInfo: true,
    badgeIcon: "icons/home-basic",
    badgeText: "common.rented",
  },
  RentedInManagement: {
    title: "common.currentContract",
    subtitle: "common.xTimeAgo",
    needsDateInfo: true,
    badgeIcon: "icons/success",
    badgeText: "common.rented",
  },
  Finalized: {
    title: "common.contractTerminated",
    subtitle: "common.xTimeAgo",
    needsDateInfo: true,
    badgeText: "common.notRented",
  },
  Lost: {
    title: "common.processCancelled",
    subtitle: "common.xTimeAgo",
    needsDateInfo: true,
    badgeText: "common.cancelled",
  },
};
