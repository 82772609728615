
import { Component, Prop, Vue } from "vue-property-decorator";
import { HousfyPropertyCard } from "housfy-ui-lib";
import { IOwnerLead } from "@/models/OwnerLeads";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import HousfyProgressCircle from "@/components/HousfyProgressCircle.vue";
import { OwnerLeadStatusCategory } from "@/enums/StatusCategory";
import { ILeadStatus } from "@/models/Status";
import { TranslateResult } from "vue-i18n";
import DateUtils from "@/services/utils/DateUtils";
import AddressUtils from "@/services/utils/AddressUtils";
import { OWNER_LEAD_STATUS } from "@/constants/OwnerLeadStatus";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { IExternalRoute, IRoute } from "@/models/IRoute";
import { IDateDiffInterval } from "@/models/DateDiffInterval";

@Component({
  name: "OwnerLeadCard",
  components: {
    HousfyPropertyCard,
    HousfySvg,
    HousfyProgressCircle,
    HousfyLink,
  },
})
export default class OwnerLeadCard extends Vue {
  @Prop({ type: Object, default: () => ({} as IOwnerLead) })
  lead!: IOwnerLead;

  @Prop({ type: String, required: true })
  dataQa!: string;

  get floorDoor(): string {
    return AddressUtils.floorDoor(this.lead.floor, this.lead.door);
  }

  get locationName(): string | null {
    return AddressUtils.locationName(this.lead.location);
  }

  get statusData(): ILeadStatus | null {
    const status = this.lead.statusCategory;
    if (status)
      return OWNER_LEAD_STATUS[status as keyof typeof OWNER_LEAD_STATUS];

    return null;
  }

  get statusCategory() {
    return OwnerLeadStatusCategory;
  }

  get isRentedInManagement() {
    return (
      this.lead.statusCategory === OwnerLeadStatusCategory.RENTED_IN_MANAGEMENT
    );
  }

  get statusSubtitle(): TranslateResult | null {
    if (!this.statusData) return null;

    if (
      this.statusData.needsStepInfo &&
      this.lead.stepsCompleted &&
      this.lead.totalSteps
    ) {
      return this.$t(this.statusData.subtitle, {
        stepsCompleted: this.lead.stepsCompleted,
        totalSteps: this.lead.totalSteps,
      });
    }

    if (this.statusData.needsDateInfo && this.lead.rentalLeasingValidityDate) {
      const { day, month, year } = DateUtils.formatDate(
        this.lead.rentalLeasingValidityDate
      );
      return day + " " + this.$t("common." + month + "Short") + " " + year;
    }

    const dateDiff: IDateDiffInterval | null = DateUtils.dateDiffInterval(
      this.lead.lastStatusChangedAt
    );

    const subtitle = this.statusData.needsStepInfo
      ? "common.xTimeAgo"
      : this.statusData.subtitle;

    if (dateDiff)
      return this.$t(subtitle, {
        n: dateDiff.value,
        d: this.$tc("common." + dateDiff.type, dateDiff.value),
      });

    return null;
  }

  get clickable(): boolean {
    const categoriesWithDetail: OwnerLeadStatusCategory[] = [
      OwnerLeadStatusCategory.PENDING_AD,
      OwnerLeadStatusCategory.PUBLISHED_AD,
    ];

    return categoriesWithDetail.includes(this.lead.statusCategory);
  }

  get containerComponent(): string {
    return this.clickable ? "HousfyLink" : "div";
  }

  get propertyLink(): IExternalRoute | IRoute {
    switch (this.lead.statusCategory) {
      case OwnerLeadStatusCategory.PENDING_AD: {
        const url = MYHExternalRoutes.dashboardProperty(this.lead.propertyUuid);
        return { ...url, path: `${url.path}?old` };
      }
      case OwnerLeadStatusCategory.PUBLISHED_AD:
        return MYHExternalRoutes.dashboardProperty(
          this.lead.propertyUuid,
          this.lead.dealId
        );
      default:
        return {
          name: "property",
          params: {
            uuid: this.lead.propertyUuid,
          },
        };
    }
  }
}
